@import url("https://fonts.googleapis.com/css2?family=Proxima+Nova");
@import url("https://fonts.googleapis.com/css2?family=Metropolis");
/** * TailwindCSS * */
@tailwind base;
@tailwind components;
@tailwind utilities;
/** * Custom * */
body {
  @apply leading-[normal] m-0;
  font-family: "metropolis", "Proxima Nova", sans-serif !important;
}
.text-xs {
  font-size: 0.75rem /* 12px */;
  line-height: 1rem /* 16px */;
}
*,
::before,
::after {
  border-width: 0;
}
